<template src="./EditMassManagementPage.html"></template>
<style scoped lang="scss" src="./EditMassManagementPage.scss"></style>

<script>
import {OrderType} from "@/constants";
import { OrderEditMixin } from '@/mixins'

const EditMassManagementForm = () => import('@/components/form/mass-management-form/MassManagementForm')

export default {
  name: 'EditMassManagementPage',
  mixins: [OrderEditMixin],
  components: { EditMassManagementForm },
  data: function () {
    return {
      orderType: OrderType.Mass
    }
  },
  methods: {
    handleOnFormCancel() {
      this.$router.push({ name: 'routes.massManagement' })
    }
  }
}
</script>
